<template>
    <!-- content -->
    <div class="container-fluid bg-lightblue py-5">
        <!-- lightbox -->
        <div>
            <CoolLightBox
                :items="lightboxItems"
                :index="lightboxIdx"
                :effect="'fade'"
                @close="lightboxIdx = null">
            </CoolLightBox>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-9">
                            <div class="row mb-5">
                                <div class="col-12">
                                    <h1 class="mp-rbk font-size-course-detail-title mp-font-weight-semi-bold d-block mb-3">{{ course.title }}</h1>
                                    <h2 class="mp-qks font-size-course-detail-intro mp-font-weight-medium mb-3">{{ course.desc_brief }}</h2>

                                    <!--
                                    <div v-if="isAuth">
                                        <a href="#" class="btn btn-mp-black-expert-detail mp-qks mp-font-weight-medium" style="margin-right: 10px" @click="toggleFavorite">
                                            <i :class="isFavoriteCourse(course.id) ? 'fas fa-heart' : 'far fa-heart'" style="margin-right: 3px"></i> {{isFavoriteCourse(course.id) ? 'Entfernen' : 'Merken' }}
                                        </a>
                                        <a href="#" class="btn btn-mp-black-expert-detail mp-qks mp-font-weight-medium"><i class="fas fa-share"></i> Teilen</a>
                                    </div>
                                    -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <!-- Tabs navs -->
                                    <ul class="nav nav-tabs mb-3" id="cdc" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active course-detail-tab-title mp-rbk py-2 pe-4 ps-0 font-size-course-detail-tab-title"
                                                    id="info-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#info"
                                                    type="button" role="tab"
                                                    aria-controls="info"
                                                    aria-selected="true">
                                                INFORMATIONEN
                                            </button>
                                        </li>
                                    </ul>
                                    <!-- /Tabs navs -->
                                    <!-- Tabs content -->
                                    <div class="tab-content" id="cdc-content">
                                        <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                                            <!-- offers tab content -->
                                            <!-- goals -->
                                            <div class="bg-white grey-border p-4 d-flex flex-column position-relative form-rounded-left form-rounded-right">
                                                <span class="course-detail-goals-title mp-rbk mp-font-weight-medium font-size-course-detail-goals-title">Ziele</span>
                                                <div class="row">
                                                    <div class="col-12 col-lg-6" v-for="o in objectiveRender" :key="`${keyPrefix}_o_${o[0]}`">
                                                        <ul class="course-detail-goals-list pt-1 ps-0 mb-0">
                                                            <li class="py-1" v-for="i in o" :key="`${keyPrefix}_i_${i}`">
                                                                <div class="row" v-if="course[`objective_${i}`]">
                                                                    <div class="col-2 col-md-1 pe-0">
                                                                        <span class="float-start goals-item font-size-course-detail-goals-item mp-qks mp-font-weight-medium"><i class="fas fa-check"></i>&nbsp;</span>
                                                                    </div>
                                                                    <div class="col-10 col-md-11 ps-0">
                                                                        <span class="float-start goals-item font-size-course-detail-goals-item mp-qks mp-font-weight-medium">{{ course[`objective_${i}`] }}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /goals -->
                                            <!-- description title-->
                                            <span class="course-detail-description-title font-size-course-detail-goals-title mp-font-weight-semi-bold mp-rbk d-block mt-5 mb-3">
                                                Beschreibung
                                            </span>
                                            <!-- /description title -->
                                            <!-- description repeat paragraphs -->
                                            <p class="expert-detail-description font-size-expert-detail-description mp-font-weight-medium mp-qks my-4">
                                                <span v-html="nl2br(course.desc, true, false)" />
                                            </p>
                                            <!-- /description -->
                                            <!-- more courses -->
                                            <span class="expert-detail-my-courses-title font-size-expert-detail-my-courses-title mp-font-weight-semi-bold mp-rbk d-block mt-5 mb-3 d-none d-lg-block">
                                                Weitere Angebote von {{ expertName }}
                                            </span>
                                            <div class="row d-none d-lg-flex">
                                                <CourseCard :course="c" v-for="c in courses" :key="`${keyPrefix}_c_${c.id}`"/>
                                            </div>
                                            <!-- /more courses -->
                                        </div>
                                    </div>
                                    <!-- /Tabs content -->
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-3">
                            <div class="row mb-2">
                                <div class="col-12 px-1 form-rounded-left form-rounded-right">
                                    <img class="w-100 form-rounded-left form-rounded-right" :src="course.media[0].url" v-if="course.media[0]" @click="lightboxIdx = 0" />
                                </div>
                            </div>
                            <div class="row mb-4" v-if="course.media.length > 1">
                                <template v-for="(e, idx) in course.media">
                                    <div v-if="idx > 0" class="col-3 px-1" :key="`course_${e.id}`">
                                        <img
                                            style="float: left; width: 100%; height: 100px; object-fit: cover;"
                                            :src="e.url"
                                            @click="lightboxIdx = idx" />
                                    </div>
                                </template>
                            </div>
                            <!-- contact data -->
                            <div class="row">
                                <div class="col-12 px-1">
                                    <div class="course-detail-contact-data bg-white p-4 grey-border form-rounded-left form-rounded-right">
                                        <!-- price -->
                                        <div class="row mb-3" v-if="course.price">
                                            <div class="col-12">
                                                <span class="mp-rbk mp-font-weight-semi-bold font-size-course-detail-price">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(course.price) }}</span>
                                            </div>
                                        </div>
                                        <!-- /price -->
                                        <!-- external link or inquiry -->
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <!-- wenn link vorhanden ist -->
                                                <a v-if="course.web_url" :href="courseUrl" target="_blank" class="btn btn-mp-green-course-detail-open-link mp-qks mp-font-weight-medium w-100" >Zum Angebot</a>
                                                <!-- sonst anfrage -->
                                                <a v-else href="#" class="btn btn-mp-green-course-detail-open-link mp-qks mp-font-weight-medium w-100" data-bs-toggle="modal" data-bs-target="#inquiry-modal">Anfrage</a>
                                                <!-- inquiry modal -->
                                                <div class="modal fade" id="inquiry-modal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="inquiry-modal-title" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down modal-lg">
                                                        <div class="modal-content p-4">
                                                            <div class="modal-header border-0 p-0 d-flex justify-content-center">
                                                                <span class="modal-title expert-detail-fields-of-experience-description-header mp-rbk mp-font-weight-semi-bold" id="inquiry-modal-title">Anfrage zu</span>
                                                                <!--                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                                                            </div>
                                                            <div class="modal-body py-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <span class="d-block text-center mp-qks mp-font-weight-semi-bold course-detail-inquiry-course-title font-size-course-detail-inquiry-course-title">{{ course.title }}</span>
                                                                        <span class="d-block text-center mp-qks mp-font-weight-semi-bold course-detail-inquiry-course-date font-size-course-detail-inquiry-course-date">{{ getCourseDate() }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row my-3 d-flex">
                                                                    <div class="col-12 col-md-6">
                                                                        <div class="mb-3">
                                                                            <label for="course-detail-inquiry-name" class="form-label mp-qks font-size-course-detail-inquiry-form-item-title mp-font-weight-medium">Name *</label>
                                                                            <input
                                                                                type="text"
                                                                                class="form-control"
                                                                                id="course-detail-inquiry-name"
                                                                                :class="$v.courseInquiry.name.$error ? 'error-border' : 'mb-3'"
                                                                                v-model="$v.courseInquiry.name.$model"
                                                                            >
                                                                            <div v-if="$v.courseInquiry.name.$error" class="error-msg">Bitte Name angeben</div>
                                                                        </div>
                                                                        <div class="mb-3">
                                                                            <label for="course-detail-inquiry-email" class="form-label mp-qks font-size-course-detail-inquiry-form-item-title mp-font-weight-medium">E-Mail-Adresse *</label>
                                                                            <input
                                                                                type="email"
                                                                                class="form-control"
                                                                                id="course-detail-inquiry-email"
                                                                                :class="$v.courseInquiry.email.$error ? 'error-border' : 'mb-3'"
                                                                                v-model="$v.courseInquiry.email.$model"
                                                                            >
                                                                            <div v-if="$v.courseInquiry.email.$error" class="error-msg">Bitte E-Mail angeben</div>
                                                                        </div>
                                                                        <div class="mb-3">
                                                                            <label for="course-detail-inquiry-phone" class="form-label mp-qks font-size-course-detail-inquiry-form-item-title mp-font-weight-medium">Telefon (optional)</label>
                                                                            <input
                                                                                type="text"
                                                                                class="form-control"
                                                                                id="course-detail-inquiry-phone"
                                                                                v-model="courseInquiry.phone"
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-md-6 d-flex flex-column">
                                                                        <div class="mb-3 flex-grow-1 d-flex flex-column">
                                                                            <label for="course-detail-inquiry-message" class="form-label mp-qks font-size-course-detail-inquiry-form-item-title mp-font-weight-medium">Nachricht *</label>
                                                                            <textarea
                                                                                class="form-control flex-grow-1"
                                                                                id="course-detail-inquiry-message"
                                                                                :class="$v.courseInquiry.msg.$error ? 'error-border' : 'mb-3'"
                                                                                v-model="$v.courseInquiry.msg.$model"
                                                                            ></textarea>
                                                                            <div v-if="$v.courseInquiry.msg.$error" class="error-msg">Bitte Nachricht angeben</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-check">
                                                                            <label class="form-check-label mp-qks font-size-course-detail-inquiry-form-item-title" for="course-detail-inquiry-accept-privacy">
                                                                                Ich akzeptiere die Weitergabe meiner persönlichen Daten zur Bearbeitung und zur Kontaktaufnahme in Bezug auf meine Anfrage.
                                                                            </label>
                                                                            <input
                                                                                class="form-check-input"
                                                                                type="checkbox"
                                                                                value=""
                                                                                id="course-detail-inquiry-accept-privacy"
                                                                                v-model="courseInquiryConsent"
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer d-block border-0 p-0 mt-3">
                                                                <div class="row">
                                                                    <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                                        <button type="button" class="btn btn-secondary font-size-btn-course-detail-inquiry mp-font-weight-medium py-2 px-5 w-100" data-bs-dismiss="modal">Abbrechen</button>
                                                                    </div>
                                                                    <div class="col-12 col-md-6">
                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-mp-green font-size-btn-course-detail-inquiry mp-font-weight-medium py-2 px-5 w-100"
                                                                            data-bs-dismiss="modal"
                                                                            :disabled="$v.$invalid || !courseInquiryConsent"
                                                                            @click="doCourseInquery"
                                                                        >Senden</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /inquiry modal -->
                                                <!-- /anfrage -->
                                            </div>
                                        </div>
                                        <!-- /external link or inquiry -->
                                        <!-- date-title -->
                                        <template v-if="course.date_start">
                                            <div class="row mb-1">
                                                <div class="col-12">
                                                    <span class="course-detail-date-title mp-rbk mp-font-weight-medium font-size-course-detail-date-title">Datum</span>
                                                </div>
                                            </div>
                                            <!-- /date-title -->
                                            <!-- date value -->
                                            <div class="row mb-3">
                                                <div class="col-12">
                                                    <span class="d-block font-size-course-detail-date-date mp-qks mp-font-weight-medium">{{ dateFormat(course.date_start, 'DD.MM.YYYY') }}</span>
                                                    <span class="d-block font-size-course-detail-date-time mp-qks mp-font-weight-medium">{{ course.time_from }} - {{ course.time_to }} Uhr</span>
                                                    <span class="d-block font-size-course-detail-date-time mp-qks mp-font-weight-medium" style="margin-top: 10px; cursor:pointer;" @click="doExportICS">
                                                        <i class="fas fa-calendar course-detail-contact-data-entry-icon align-self-center" style="padding-right: 3px"></i>
                                                        Termin exportieren
                                                    </span>
                                                </div>
                                            </div>
                                        </template>

                                        <!-- offer-contents-title -->
                                        <template v-if="course.desc_duration || course.desc_learning_material">
                                            <div class="row mb-1">
                                                <div class="col-12">
                                                    <span class="course-detail-offer-contents-title mp-rbk mp-font-weight-medium font-size-course-detail-offer-contents-title">Im Angebot enthalten</span>
                                                </div>
                                            </div>
                                            <!-- /offer-contents-title -->
                                            <!-- offer-contents-value -->
                                            <div class="row mb-3">
                                                <div class="col-12">
                                                    <ul class="course-detail-offer-contents-list mb-0 ps-0">
                                                        <li class="py-1"><span class="font-size-course-detail-offers-content-item mp-qks mp-font-weight-medium"><i class="fas fa-check"></i> {{ course.desc_units }} </span></li>
                                                        <li v-if="course.desc_duration" class="py-1"><span class="font-size-course-detail-offers-content-item mp-qks mp-font-weight-medium"><i class="fas fa-check"></i> {{ course.desc_duration }} </span></li>
                                                        <li v-if="course.desc_learning_material" class="py-1"><span class="font-size-course-detail-offers-content-item mp-qks mp-font-weight-medium"><i class="fas fa-check"></i> {{ course.desc_learning_material }} </span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </template>

                                        <!-- /offer-contents-value -->
                                        <!-- expert-title -->
                                        <div class="row mb-1">
                                            <div class="col-12">
                                                <span class="course-detail-expert-title mp-rbk mp-font-weight-medium font-size-course-detail-expert-title">Experte</span>
                                            </div>
                                        </div>
                                        <!-- /expert-title -->
                                        <!-- expert-value -->
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <span class="d-block mb-1 font-size-course-detail-expert-content mp-qks mp-font-weight-medium">{{ expertName }}</span>
                                                <img class="w-100 form-rounded-left form-rounded-right" :src="expert.media[0].url" v-if="expert.media[0]" @click="gotoExpert" style="cursor: pointer;" />
                                            </div>
                                        </div>
                                        <!-- /expert-value -->
                                        <!-- place-title -->
                                        <div class="row mb-1">
                                            <div class="col-12">
                                                <span class="course-detail-place-title mp-rbk mp-font-weight-medium font-size-course-detail-place-title">Ort</span>
                                            </div>
                                        </div>
                                        <!-- /place-title -->
                                        <!-- place-value -->
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <template v-if="course.is_online_only">
                                                    <span class="d-block font-size-course-detail-place-company mp-qks mp-font-weight-medium">Online</span>
                                                </template>
                                                <template v-else>
                                                    <span class="d-block font-size-course-detail-place-company mp-qks mp-font-weight-medium">{{ expert.company_title }}</span>
                                                    <span class="d-block font-size-course-detail-place-street mp-qks mp-font-weight-medium">{{ course.address }}</span>
                                                    <span class="d-block font-size-course-detail-place-zip-city mp-qks mp-font-weight-medium">{{ course.zip }} {{ course.city }}</span>
                                                </template>
                                            </div>
                                        </div>
                                        <!-- /place-value -->

                                        <!-- place map - insert google maps view!!! -->
                                        <div class="row">
                                            <div class="col-12">
                                                <gmaps-map
                                                    class="map"
                                                    :options="mapOptions"
                                                    v-if="course.gps_lat && course.gps_long"
                                                >
                                                    <template v-slot:loading>
                                                        <div>
                                                            <span>This is now loading...</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:error="{ error }">
                                                        <div>
                                                            <span>You broke it: {{ error }}</span>
                                                        </div>
                                                    </template>
                                                    <gmaps-marker :position="{lat: parseFloat(course.gps_lat), lng: parseFloat(course.gps_long)}" />
                                                </gmaps-map>
                                            </div>
                                        </div>
                                        <!-- /place map -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /content -->
</template>



<script>
    import {mixinBasics} from "../../mixins/basics";
    import CourseCard from "../../components/CourseCard";
    import "../../assets/js/icsFormatter";
    import {icsFormatter} from "../../assets/js/icsFormatter";
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
    import {apiCall, buildFinalUrl} from "../../helpers";
    import {mapActions} from "vuex";
    import Configs from "../../configs";
    import {gmapsMap, gmapsMarker} from 'x5-gmaps'
    import {email, required} from "vuelidate/lib/validators";

    const api = Configs.restApi.endpoints.map;


    export default {
        name: "CourseDetail",

        components: {
            CourseCard,
            CoolLightBox,
            gmapsMap,
            gmapsMarker
        },

        mixins: [
            mixinBasics
        ],

        metaInfo() {
            return {
                title: this.metaTitle,
                meta: [
                    {name: 'Kurs'},
                    {title: this.metaTitle}
                ]
            };
        },

        props: {
            id: {
                required: true
            }
        },

        data() {
            return {
                keyPrefix: 'courseD',
                lightboxIdx: null,
                objectiveRender: [ [1,2,3], [4,5,6] ],    // +1 point for "silly-solutions",
                courseInquiry: {
                    name: undefined,
                    email: undefined,
                    phone: undefined,
                    msg: undefined,
                },
                courseInquiryConsent: false
            };
        },

        validations() {
            return {
                courseInquiry: {
                    name: { required },
                    email: { required, email },
                    msg: { required },
                }
            };
        },

        computed: {
            course() {
                return this._courses.find(e => e.id === parseInt(this.id));
            },

            courses() {
                let courses = this._courses.filter(c => c.user_id === this.course.user_id);
                return courses.length > 3 ? courses.slice(0,3) : courses;
            },

            expert() {
                return this._experts.find(e => e.user_id === this.course.user_id);
            },

            expertName() {
                return `${this.expert.title ? this.expert.title : ''} ${this.expert.firstname} ${this.expert.lastname}${this.expert.title_trailing ? ', ' + this.expert.title_trailing : ''}`
            },

            expertNameMeta() {
                return `${this.expert.firstname}-${this.expert.lastname}`;
            },

            courseDateDow() {
                let d = new Date(this.course.date_start);
                return d.toLocaleDateString('de-DE', { weekday: 'short' }).toUpperCase();
            },

            courseIcsLocation() {
                return `${this.course.address} ${this.course.zip} ${this.course.city}`;
            },

            lightboxItems() {
                return this.course.media.map(m => m.url);
            },

            courseUrl() {
                if (this.course.web_url) {
                    if (this.course.web_url.indexOf('https') === -1) {
                        if (this.course.web_url.indexOf('http') >= 0) {
                            return this.course.web_url.replace(/http/i, "https");
                        } else {
                            return `https://${this.course.web_url}`;
                        }
                    }
                }
                return this.course.web_url;
            },

            mapOptions() {
                return {
                    mapTypeControl: false,
                    panControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                    center: {lat: parseFloat(this.course.gps_lat), lng: parseFloat(this.course.gps_long)},
                    zoom: 16
                };
            },

            metaTitle() {
                return `Kurs - ${ this.course.title }`;
            }
        },

        methods: {
            ...mapActions('main', {
                _loadUserProfile: 'loadUserProfile',
            }),

            doExportICS() {
                let cal = icsFormatter();
                cal.addEvent(
                    this.course.desc_brief,
                    this.course.desc,
                    this.courseIcsLocation,
                    `${this.course.date_start} ${this.course.time_from}`,
                    `${this.course.date_start} ${this.course.time_to}`
                );
                cal.download();
            },

            async toggleFavorite() {
                let favs = this.isFavoriteCourse(this.course.id) ? this.favRemove() : this.favAdd();
                await this.doUserFavoritesSave(favs);
            },

            favAdd() {
                let res = this.profile.favoriteCourses.map(e => e.id);
                res.push(this.course.id);
                return res;
            },

            favRemove() {
                return this.profile.favoriteCourses.filter(e => e.id !== this.course.id).map(e => e.id);
            },

            async doUserFavoritesSave(favs) {
                let payload = {favorite_courses: favs};
                let req = api.user.update;
                await apiCall(req.url, req.method, payload, false);
                await this._loadUserProfile(true);
            },

            gotoExpert() {
                this.$router.push(`/experten/${this.expertNameMeta}/${this.expert.id}/detail`);
            },

            async doCourseInquery() {
                let req = api.course.inquiry;
                let payload = this.courseInquiry;
                await apiCall(buildFinalUrl(req.url, {id: this.id}), req.method, payload);
            },

            getCourseDate() {
                if (this.course.date_start) {
                    let d = new Date(this.course.date_start);
                    return `${d.toLocaleDateString('de-DE', {weekday: 'short'}).toUpperCase()}, ${this.dateFormat(this.course.date_start, 'DD.MM.YYYY')}`;
                } else {
                    return '';
                }
            }
        },

        mounted() {
            window.scrollTo(0,0);
        }
    }
</script>



<style scoped>
    img {
        cursor: pointer;
    }
    .map {
        width: 100%;
        height: 180px;
        display: inline-block;
        max-height: 100%;
        overflow: auto;
        border: none;
        background-color: rgb(229, 227, 223);
    }
    .error-msg {
        color: red;
        margin-top: 0px;
    }
    .error-border {
        border: solid 1px red;
    }
</style>
